<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header bg-transparent header-elements-sm-inline py-sm-0">

      <h5 class="card-title py-sm-3"><span class="icon-list"></span>&nbsp;&nbsp;Brand's List</h5>

      <div class="header-elements">
        <form action="#">
          <div class="form-group-feedback form-group-feedback-right">
            <input  id="txtsearch" type="search" class="form-control wmin-sm-200" placeholder="Search..." autocomplete="off">
            <div class="form-control-feedback">
              <i class="icon-search4 font-size-base text-muted"></i>
            </div>
          </div>
        </form>

      </div>
    </div>


    <div class="table-responsive">
      <table id="brand-table"
             class="table  table-hover table-bordered table-condensed table-columned"
             data-search="false"
             data-pagination="true"
             data-show-refresh="false"
             data-show-columns="false"
             data-page-list="[10, 25, 50, 100, ALL]"
             data-show-footer="false"
             data-toggle="context"
             data-target=".context-table">
        <thead>
          <tr>
            <th data-field="id" data-class="d-none">id</th>
            <th data-field="id" data-width="100">Code</th>
            <th data-field="status" >Status</th>
            <th data-field="name" >Name</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
        <tfoot>
        </tfoot>
      </table>
    </div>

    <!-- Context Menu -->
    <div id="context-menu" class="context-table">
      <div class="dropdown-menu">
        <a href="#" class="dropdown-item" @click="loadData"><i class="icon-reset" style="color: darkgreen"></i>Refresh</a>
        <!--<div class="dropdown-divider"></div>-->
        <!--<a href="#" class="dropdown-item"><i class="icon-cross2 text-danger"></i>Discard</a>-->
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"> <i class="icon-pencil3" ></i>Modify</a>
        <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item"><i class="icon-blocked text-danger"></i>Remove</a>
      </div>
    </div>
    <!-- End of Context Menu -->

    <!-- Clickable menu -->
    <ul class="fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck" data-fab-toggle="hover" z-index="99">
      <li>
        <a class="fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon" @click="showModal">
          <i class="fab-icon-open icon-plus3"></i>
          <i class="fab-icon-close icon-plus3"></i>
        </a>
      </li>
    </ul>
    <!-- /clickable menu -->

    <vmodal name="brand_window" transition="nice-modal-fade" :delay="100" :resizable="true" width="42%" height="40%" >
      <BrandForm v-bind:mybrand="brand" v-on:brand_window_closed="closeModal" v-on:brand_saved="loadData" ></BrandForm>
    </vmodal>


  </div>
  </FocusTrap>
</template>

<script>

import BrandForm from './BrandForm'
import FabButton from '@/components/core/FabButton.vue'
import moment from 'moment-timezone'
import {userService} from '@/store/auth-header.js'
export default {
  name: 'BrandView',
  components: {
    BrandForm,
    FabButton
  },
  data () {
    return {
      mytable: {},
      brand: JSON.parse('{"id":0,"status":"","name":""}')
    }
  },
  created () {

  },
  mounted () {
    let self = this;

    this.$data.mytable = $('#brand-table');
    this.$data.mytable.bootstrapTable();
    $('[data-toggle="popover"]').popover();

    this.$data.mytable.contextmenu({
      target: '#context-menu',
      scopes: 'tbody > tr',
      onItem: function (row, e) {
        let id = $(row.children('*')[0]).text();

        if ($(e.target).text() === 'Modify') {
          self.modifyDocument (id);
        } else if ($(e.target).text() === 'Remove') {
          self.removeDocument(id);
        }
      }
    });

    $('#txtsearch').keyup(function () {
      let val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase()
      $('#fcc-table>tbody>tr').show().filter(function () {
        let text = $(this).text().replace(/\s+/g, ' ').toLowerCase()
        return !~text.indexOf(val)
      }).hide()
    });

    self.loadData();
  },
  methods: {
    closeModal () {
      this.$modal.hide('brand_window');
      this.$data.brand = JSON.parse('{"id":0,"status":"","name":""}');
    },
    showModal () {
      this.$modal.show('brand_window');
    },
    beforeOpen(){
      // console.log('beforeOpen');
    },
    beforeClose(){
      // this.loadData();
    },
    loadData () {
      const self = this;
      this.$modal.hide('brand_window');

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.mytable.bootstrapTable('load',[]);

      fetch(`${process.env.VUE_APP_ROOT_API}api/brands/after/1/`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.ok) {
          self.$data.mytable.bootstrapTable('load', data.data);
        } else {
          swal ( { title: "Oops!" ,  text: data.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });

    },
    modifyDocument (id) {
      const self = this;

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      self.$data.brand = JSON.parse('{"id":0,"status":"","name":""}');


      fetch(`${process.env.VUE_APP_ROOT_API}api/brand/${id}`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.ok) {
          self.$data.brand = resp.data;
          self.$modal.show('brand_window');
        } else {
          swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text:  err.toString() , type:  "error"} );
      });
    },
    removeDocument (id) {
      let self = this;

      self.$data.brand.id = id;

      const requestOptions = {
        method: 'DELETE',
        mode:'cors',
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.brand)
      };

      swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        timer: 3000,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete tms!'
      }).then((result) => {
        if (result.value) {
          fetch(`${process.env.VUE_APP_ROOT_API}api/brand`,requestOptions).then(userService.handleResponse).then(function (resp) {
            self.$modal.hide('brand_window')
            self.loadData();
            swal('Deleted!', 'Your request has been processed', 'success');
          }).catch(function (err) {
            swal({ title: "Oops", text: err.toString(), type: "error" });
          });
        }
      });
    },
    saveDocument () {
      const self = this;

      if(self.$data.brand.name.trim().length < 1){
        alert("Invalid Name");
        return
      }

      const requestOptions = {
        method:  (self.$data.brand.id == 0 ? 'POST' : 'PUT'),
        mode:"cors",
        headers: userService.authHeader(),
        body: JSON.stringify(self.$data.brand)
      };

      fetch(`${process.env.VUE_APP_ROOT_API}api/brand`,requestOptions).then(userService.handleResponse).then(function (data) {
        if(data.ok) {
          swal ( { title: "Success!" ,  text: data.msg, type:  "success"} );
          self.brand = JSON.parse('{"id":0,"status":"","name":""}');
          self.closeModal();
        } else {
          swal ( { title: "Oops!" ,  text: data.msg, type:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
      });
    }
  }
}
</script>

<style scoped>

</style>
